import {Component, effect, inject, input, Input, output, ViewEncapsulation} from '@angular/core';
import {
  ECalculateImportValue,
  EControlActions,
  IControlDataChanged,
  SignalControl,
  TableService
} from 'frontier/nucleus';
import {ITableVerticalDataRow} from './table-vertical-api-adapter';
import {IApiRow} from '../table-control/api-row.interface';
import {CellEditorComponent} from './cell-editor/cell-editor.component';
import {NgFor, NgIf} from '@angular/common';
import {map} from 'rxjs';
import {patchState} from '@ngrx/signals';
import {IApiTableData} from '../table-control/interfaces/api-table-data.interface';
import {MatProgressBar} from '@angular/material/progress-bar';
import {MatProgressSpinner} from '@angular/material/progress-spinner';

@Component({
  selector: 'kpi4me-table-vertical-control',
  templateUrl: './table-vertical-control.component.html',
  styleUrls: ['./table-vertical-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    MatProgressSpinner,
    MatProgressBar,
    NgFor,
    CellEditorComponent,
    NgIf,
  ],
})
export class TableVerticalControlComponent extends SignalControl<IApiTableData, ITableVerticalDataRow[]> {
  private _tableService = inject(TableService);
  @Input() header: string;
  hasImports = input<false>(false);
  showImports = input<boolean>(false);
  rows = this.data;
  showImportsChange = output<boolean>();
  cellChanged = output<{ apiRow: IApiRow, verticalRow: ITableVerticalDataRow }>();

  constructor() {
    super();

    effect(() => {
      // Fetch new values only if both showImports has been changed to true and the imports haven't already been loaded
      const showImports = this.showImports();
      const apiInstance = this.apiInstance();
      if (apiInstance) {
        this.patchFilter({
          calculateimportvalue: showImports ? ECalculateImportValue.first : ECalculateImportValue.off
        })
      }
    }, {allowSignalWrites: true});
  }

  override toModel(_: IApiTableData) {
    return this.fetchRows();
  }

  fetchRows() {
    return this._tableService.tablePostFetchRows(
      {
        _parameters: [
          this.instanceId(),
          0,
          1,
          0,
          this.apiData().displayedColumns.length - 1
        ]
      }
    ).pipe(map((_) => {
      const rows = _ as IApiRow[];
      return this.mapRows(rows);
    }));
  }

  private mapRows(rows: IApiRow[]) {
    if (rows.length != 1) {
      console.warn(`Unexpected number of rows: ${rows.length}. Only expected one row for the client. Is the client filter applied?`);
      return null;
    }
    const newRows: ITableVerticalDataRow[] = [];
    console.log(rows);
    for (const col of this.apiData().displayedColumns) {
      const cell = rows[0].cols[Number(col.field)];
      newRows.push({
        type: col.type,
        attributeIndex: col.attributeindex,
        attribute: col.attribute,
        fieldName: col.headerName,
        value: cell.value,
        editable: cell.editable,
        apiRow: rows[0],
        apiInstance: this.apiInstance(),
        repository: col.repository,
        invalidMessage: cell.invalidmessage ? cell.invalidmessage : null,
        imported: cell.imported
      })
    }
    return newRows;
  }

  onCellChange(row: { apiRow: IApiRow, verticalRow: ITableVerticalDataRow }) {
    this.cellChanged.emit(row);

    const mappedRows =  this.apiData().displayedColumns.map(col => {
      const cell = row.apiRow.cols[Number(col.field)];
      const apiRow = row.apiRow;
      const newRow = {
        type: col.type,
        attributeIndex: col.attributeindex,
        attribute: col.attribute,
        fieldName: col.headerName,
        value: cell.value,
        editable: cell.editable,
        apiRow: apiRow,
        apiInstance: this.apiInstance(),
        repository: col.repository,
        invalidMessage: cell.invalidmessage ? cell.invalidmessage : null,
        imported: cell.imported
      }
      return newRow;
    });


    patchState(this.state, state => {
      const rows = state.data;
      rows.forEach((row, index) => {
        row.value = mappedRows[index].value;
        row.invalidMessage = mappedRows[index].invalidMessage;
        row.component?.updateRow(row);
      })
      return {data: rows};
    });

    this.controlStore.controlDataChanged$.emit({GUID: this.GUID, changeType: EControlActions.changeLine});
  }

  onUpdateTable() {
    this.changeAndFetchInstance();
  }

  onUpdateRepositories() {
    this.changeAndFetchInstance();
  }

  onSelectionDataChange(evt: IControlDataChanged) {
    this.controlStore.controlDataChanged$.emit({changeType: evt.changeType, GUID: this.GUID});
  }
}
