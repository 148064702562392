import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  HostListener,
  inject,
  input,
  Input,
  model,
  ModelSignal,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  signal,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  BodyScrollEvent,
  CellClickedEvent,
  CellContextMenuEvent,
  CellDoubleClickedEvent,
  CellMouseDownEvent,
  CellValueChangedEvent,
  ColDef,
  ColumnApi,
  FilterModifiedEvent,
  FirstDataRenderedEvent,
  GetRowIdFunc,
  GridApi,
  GridOptions,
  IDatasource,
  IGetRowsParams,
  RowClickedEvent,
  RowNode,
  RowSelectedEvent,
  SortChangedEvent,
  VirtualColumnsChangedEvent,
} from 'ag-grid-community';
import {IGridReady} from './interfaces/grid-ready.interface';
import {SharedGridOptions} from './shared-gridoptions.const';
import {TableApiAdapter} from '../adapters/table/table-api-adapter';
import {ITableAction} from './interfaces/actions.interface';
import {DEFAULT_TABLE_ACTIONS} from './default-table-actions.constant';
import {BehaviorSubject, combineLatest, distinctUntilChanged, forkJoin, merge, Observable, of, timer} from 'rxjs';
import {
  catchError,
  debounce,
  debounceTime,
  filter,
  finalize,
  map,
  mergeMap,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import {ITableControlFilter} from './filter.interface';
import {ICustomColDef} from './interfaces/custom-col-def.interface';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {sameArrayValues} from './same-array-values.function';
import {ColTypeColDefMap} from './col-type-col-def.map';
import {ViewPort, ViewPortTable} from './viewport';
import {EControlActions, SignalControl, StoreService, TableControlService, TableService} from 'frontier/nucleus';
import {DisplayedColumns} from './interfaces/displayed-columns.interface';
import {IApiRow} from './api-row.interface';
import {RowEvent} from "ag-grid-community/dist/lib/events";
import {AgGridAngular} from "ag-grid-angular";
import {Clipboard} from '@angular/cdk/clipboard';
import {MatDialog} from "@angular/material/dialog";
import {IApiTableData} from './interfaces/api-table-data.interface';
import {ICustomGridRow} from './interfaces/custom-grid-row.interface';
import {Column} from 'ag-grid-community/dist/lib/entities/column';
import {IColumnFilter} from './interfaces/column-filter.interface';
import {DomSanitizer} from '@angular/platform-browser';
import {patchState} from '@ngrx/signals';
import {FeedbackService} from '../../../services/feedback.service';
import {ClipboardService} from '../../../services/clipboard.service';
import {IPdfBlob} from './pdf-blob-event.interface';
import {HttpResponse} from "@angular/common/http";
import {ToolbarStore} from 'frontier/browserkit';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';

interface IApiSortModel {
  sortby?: string[];
  direction?: (0 | 1)[];
}

@Component({
  selector: 'app-table-control',
  templateUrl: './table-control.component.html',
  styleUrls: ['./table-control.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableControlComponent extends SignalControl<IApiTableData, void>
  implements OnInit, OnDestroy, AfterViewInit {
  private _toolbarService = inject(ToolbarStore)
  protected _tableService: TableService = inject(TableService);
  protected _tableControlService: TableControlService = inject(TableControlService);

  protected readonly feedbackService: FeedbackService = inject(FeedbackService);
  public readonly cdr = inject(ChangeDetectorRef);
  protected readonly store = inject(StoreService);
  protected readonly dialog = inject(MatDialog);
  protected readonly clipboard = inject(Clipboard);
  protected readonly clipboardService = inject(ClipboardService);
  protected readonly zone = inject(NgZone);
  protected readonly sanitizer = inject(DomSanitizer);
  protected readonly elementRef: ElementRef = inject(ElementRef);

  tableName: string;
  gridApi: GridApi;
  readonly gridApiSig = signal<GridApi>(null);
  colApi: ColumnApi;
  columnDefs: ColDef[];
  apiCols: DisplayedColumns[];

  @ViewChild(AgGridAngular) gridRef: AgGridAngular;
  @ViewChildren('grid', {read: ElementRef}) gridElementRefs: QueryList<ElementRef>;
  @ViewChild('gridWrapper', {read: ElementRef}) gridWrapperRef: ElementRef;
  @ViewChild('fileUpload') fileUploadRef: ElementRef;
  @ViewChild('pdfOverlay', {read: ElementRef}) pdfOverlayRef: ElementRef;
  // This is a backup variable. In the first line the Windows clipboard is used for Copy and Paste. This variable is
  // accessed where the Windows Variable would convert the copied value or when browsers/browser versions are used,
  // that do not support the Paste implementation from Windows clipboard.
  clipboardValue: any;

  /**
   * Grid options of the ag grid component. If you need additional options you can inherit the SharedGridOptions
   * and add properties, or you can also pass your own grid options.
   */
  @Input() gridOptions: GridOptions = {
    ...SharedGridOptions,
  };

  @Input() gridClass = 'ag-theme-alpine';

  /**
   * The adapter that the table uses to map api rows to ag grid rows.
   * If some column properties have to be added or edited before the columns are passed to the ag grid,
   * you can create another Table api adapter to adapt necessary changes.
   */
  @Input() apiAdapter: TableApiAdapter = new TableApiAdapter(
    {},
    ColTypeColDefMap
  );

  private _fixedPageSize: number;
  @Input() set fixedPageSize(pageSize: number) {
    this._fixedPageSize = pageSize;
    this.setPaginationPageSize();
  };

  get fixedPageSize() {
    return this._fixedPageSize;
  }

  /**
   * The filters that will be displayed with checkboxes above the table.
   */
  filters = input<ITableControlFilter[]>([]);
  /**
   * The actions that will be displayed as buttons above the table.
   */
  actions: ModelSignal<ITableAction[]> = model<ITableAction[]>(DEFAULT_TABLE_ACTIONS);
  pdf = signal<IPdfBlob>(null);
  pdfSrc = computed(() => {
    const pdf = this.pdf();
    const url = pdf?.blob ? this.sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(pdf.blob)) : null
    console.log('pdfSrc', url);
    return url;
  });

  @Input() dataSource: IDatasource = {
    rowCount: null,
    getRows: (params: IGetRowsParams) => {
      patchState(this.state, _ => ({loading: true}));
      // increment request counter. If the counter is zero, it is the first request
      this.openGetRowReqs$.next(
        this.openGetRowReqs$.getValue() == null
          ? 1
          : this.openGetRowReqs$.getValue() + 1
      );
      combineLatest([
        this.apiInstance$.pipe(filter(v => {
          return v != null
        })),
        this.currentViewPort$,
        this.gridApiInitialized$
      ]).pipe(
        takeUntil(
          this.cancel$.pipe(
            filter(v => v === true),
            tap(_ => {
              this.openGetRowReqs$.next(this.openGetRowReqs$.getValue() - 1);
              patchState(this.state, _ => ({loading: false}));
              params.failCallback();
            })
          )
        ),
        take(1),
        tap(() => {
          console.log('getRows', params);
          const endRow =
            params.endRow < this.rowCount ? params.endRow : this.rowCount;
          const startRow =
            params.startRow >= params.endRow ? Math.max(0, params.endRow - this.gridOptions.cacheBlockSize) : params.startRow;
          const newViewPort = new ViewPort(
            startRow,
            endRow,
            this.lazyLoadColumns === false ? 0 : this.currentViewPort$.getValue().startCol,
            this.lazyLoadColumns === false ? this.apiCols.length - 1 : this.currentViewPort$.getValue().endCol
          );
          this.currentViewPort$.next(newViewPort);
        }),
        mergeMap(() => {
          return this.fetchViewport();
        })
      ).subscribe(
        {
          next: (rowBlock: ICustomGridRow[]) => {
            console.log(rowBlock);
            params.successCallback(rowBlock, this.rowCount);
            this.cdr.detectChanges();
            // decrement the counter for the open requests
            this.openGetRowReqs$.next(this.openGetRowReqs$?.getValue() - 1);
            // Select all rows
            if (this.selectedAll) {
              rowBlock.forEach((row: ICustomGridRow) => {
                const gridRow = this.gridApi.getRowNode(row.apiRow.id);
                if (gridRow) {
                  this.setInitialSelection(gridRow);
                }
              });
            } else {
              // reset previous selection
              console.log(this.savedSelectedRowsIds);
              this.savedSelectedRowsIds.forEach((id) => {
                const gridRow = this.gridApi.getRowNode(id);
                if (gridRow) {
                  this.setInitialSelection(gridRow);
                }
              });
            }
            this.rowDataChanged.emit({selected: this.gridApi.getSelectedRows(), all: rowBlock});
            this.fitColumns();
            patchState(this.state, _ => ({loading: false}));

          },
          error: () => {
            // decrement the counter
            this.openGetRowReqs$.next(this.openGetRowReqs$.getValue() - 1);
            patchState(this.state, _ => ({loading: false}));
            params.failCallback();
          }
        })
    },
  };

  /**
   * Event for custom table actions. If the actions input above differs from the DEFAULT_TABLE_ACTIONS and you need
   * to react in the parent component of this one, you can listen to the custom action output.
   */
  @Output() customAction = new EventEmitter<EControlActions>();

  /**
   * Reference to the parent component.
   */
  @Input() parentRef: any;
  /**
   * Indices of columns that are static.
   */
  @Input() staticColumns: number[] = [];

  @Input() lazyLoadColumns = false;

  /**
   * Event that fires when the ag grid is ready. The api for the ag grid is ready when the event fires.
   */
  @Output() gridReady = new EventEmitter<IGridReady>();
  /**
   * One Row of the table is deleted, the api request is finished successfully, when it triggers.
   */
  @Output() rowDeleted = new EventEmitter<RowNode>();

  /**
   * A new row was created. The api request is finished successfully, when it triggers.
   */
  @Output() rowCreated = new EventEmitter<IApiRow>();
  /**
   * Column index that will get edited after a new line was created.
   */
  @Input() editAfterCreateIndex = 0;

  /**
   * Event that triggers when a cell value has changed.
   */
  @Output() cellValueChanged = new EventEmitter<CellValueChangedEvent>();
  /**
   * Emits when a form cell editor gets confirmed.
   * Is needed in the easy plan table to check if the date is in range of the current date range filter.
   */
  @Output() formCellEditorConfirmed = new EventEmitter<{
    result: boolean;
    date: NgbDate;
  }>();

  @Output() paginationChanged$ = new EventEmitter();
  @Output() rowSelected = new EventEmitter<RowSelectedEvent>();
  @Output() rowDeselected = new EventEmitter<RowSelectedEvent>();
  @Output() cellDoubleClicked = new EventEmitter<CellDoubleClickedEvent>();
  @Output() cellClicked = new EventEmitter<CellClickedEvent>();
  @Output() currentViewPortChanged = new EventEmitter<ViewPort>();
  @Output() rowDataChanged = new EventEmitter<{ selected: ICustomGridRow[], all: ICustomGridRow[] }>();
  @Output() firstDataRendered = new EventEmitter<FirstDataRenderedEvent>();

  @Output() rowsAdded = new EventEmitter<RowEvent>();

  scrollThrottleTimeout: ReturnType<typeof setTimeout>; // Variable to store the throttle timeout

  // currently selected rows
  selectedRows: ICustomGridRow[] = [];

  // Client side table model. It stores all fetched cells from the api. It is then used to merge data that already
  // lies on client side, with new data that is loaded when getRows is triggered with a new viewport.
  viewPortTable = new ViewPortTable();

  context: TableControlComponent;

  private _rowCount: number;
  // The saved rows will get selected again after getting data from getRows.
  protected savedSelectedRowsIds: string[] = [];
  // client column filter / sort has changed
  private columFilterOrSortChange$ = new EventEmitter<SortChangedEvent | FilterModifiedEvent>();
  // Helper Observable to cancel open getRows. Should be fired when the instance has changed or the filter / sorting
  private cancel$ = new BehaviorSubject(false);
  // Observable for open getRow Requests
  private gridApiInitialized$ = new BehaviorSubject(false);

  private openGetRowReqs$ = new BehaviorSubject<number>(null);

  private debouncedVirtualColumnChange$ = new EventEmitter<VirtualColumnsChangedEvent>();
  private currentViewPort$ = new BehaviorSubject<ViewPort>(
    new ViewPort(0, 0, 0, 0)
  );

  // The debounce time in ms for the virtual column change event.
  // Used to prevent overloading of api calls for loading cells of the table.
  private virtualColumnLoadingTime = 300;

  private selectedAll = false;

  private gridContainerHeight$ = new BehaviorSubject<number>(0);
  private automaticallySetRowSelection = new Set();

  // saves the selected rows before changing and fetching data.
  protected get rowCount(): number {
    return this._rowCount;
  }

  protected set rowCount(n: number) {
    if (this.gridApi) {
      this.gridApi.setRowCount(n);
    }
    this._rowCount = n;
  }

  override toModel(d: IApiTableData) {
    this.rowCount = d.rowcount;
    // if (this.gridApi) {
    //   this.gridApi.deselectAll();
    //   this.refreshInfiniteCache();
    //   this.gridApi.hideOverlay();
    // }

    const tempData = this.apiAdapter.from(d);

    this.columnDefs = tempData.columnDefs;
    this.apiCols = tempData.displayedColumns;
    if (this.dataSource) {
      this.initializeDataSource(this.gridApi, this.dataSource);
    }

    this.cdr.detectChanges();
    this.fitColumns();
    this.gridApi?.refreshHeader();
  }

  constructor() {
    super();
    this.context = this;
    effect(() => {
      const pdf = this.pdf();
      if (pdf) {
        this.showPdfOverlay(pdf);
      } else {
        this.hidePdfOverlay();
      }
    })
    this._toolbarService.resetFullTextFilter$.pipe(
      takeUntilDestroyed()
    ).subscribe(() => {
      this.gridApi.setFilterModel(null);
    })
  }


  override ngOnInit() {
    super.ngOnInit();
    this.subs.add(
      this.debouncedVirtualColumnChange$
        .pipe(
          filter(() => this.lazyLoadColumns === true),
          debounceTime(this.virtualColumnLoadingTime),
          filter(
            (virtualColumnChange) =>
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (virtualColumnChange.columnApi).columnModel != null
          )
        )
        .subscribe((virtualColumnChange) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const colFields: { field: string; key: string }[] = (virtualColumnChange.columnApi).columnModel
            .viewportColumns.map((c: Column) => {
              return {
                field: (c.getColDef() as ICustomColDef).apiCol.field,
              };
            });
          if (colFields.length <= 0) {
            return;
          }
          console.log('debounced col change', colFields, this.staticColumns);

          const newViewPort = new ViewPort(
            this.currentViewPort$.getValue().startRow,
            this.currentViewPort$.getValue().endRow,
            Number(colFields[0].field),
            colFields.length == this.staticColumns.length
              ? Number(colFields[colFields.length - 1].field)
              : Number(
                colFields[colFields.length - 1 - this.staticColumns.length]
                  .field
              )
          );
          if (newViewPort.equals(this.currentViewPort$.getValue())) {
            return;
          }
          this.currentViewPort$.next(newViewPort);
          const vp = this.viewPortTable.viewPortChanged(
            this.currentViewPort$.getValue(),
            'col'
          );
          if (vp) {
            console.log('viewport changed. refreshing data');
            // Trigger the function get rows() to get the new viewport from the server
            this.refreshInfiniteCache();
          }
        })
    );

    // debounced col filter
    this.subs.add(
      this.columFilterOrSortChange$
        .pipe(
          tap(_ => {
            this.cancel$.next(true)
          }),
          // tap(_ => this.gridApi.showLoadingOverlay()),
          debounce(() => timer(500)),
          mergeMap(() => this.changeFilterAndSorting()),
          filter(errored => {
            if (errored == null) {
              this.cancel$.next(false)
            }
            return errored != null
          })
        )
        .subscribe((value) => {
          // Set the row count for the get row request
          this.rowCount = value as number;
          // Set row count for the api to display n empty rows
          // if Get rows was triggered before receiving the rowcount, it will be the bottleneck until changedInstance is true;
          this.refreshInfiniteCache();
          console.log(value);
        })
    );

    // Listen to all get row calls and when they are finished, hide the loading overlay.
    this.subs.add(
      this.openGetRowReqs$
        .pipe(
          filter((n: number) => {
            return n === 0;
          })
        )
        .subscribe(() => {
          if (this.rowCount <= 0) {
            this.gridApi.showNoRowsOverlay();
          } else {
            this.gridApi.hideOverlay();
            this.rowsAdded.emit();
          }
        })
    );

    // If the height of the grid container changes, re-calculate the pagination size
    this.subs.add(
      this.gridContainerHeight$.pipe(
        filter(v => v != 0),
        debounceTime(30),
        distinctUntilChanged(),
        tap((v) => {
          console.log('Grid container height changed. Resetting the pagination page size. Height is: ', v);
          this.setPaginationPageSize();
        })
      ).subscribe()
    )
  }

  ngAfterViewInit() {

  }

  override ngOnDestroy() {
    super.ngOnDestroy();
    this.resetRowCache();
  }

  changeFilterAndSorting(): Observable<false | number> {
    // build column filter
    if (this.gridApi == null || this.apiInstance() == null) return of(false)
    const filterModel = this.gridApi.getFilterModel();
    const columnFilter = this.getApiFilterModel(filterModel);

    // build column sort
    const sortModel = this.colApi.getColumnState().map((c) => {
      return {colId: c.colId, sort: c.sort, sortIndex: c.sortIndex};
    });
    const apiSortModel = this.getApiSortModel(sortModel);

    console.log('filterModel', filterModel);
    console.log('sortModel', apiSortModel);

    return this.queueRequest(
      this._tableService.tablePostChangeFilterAndSorting({
          _parameters: [
            this.apiInstance().instanceid,
            columnFilter,
            apiSortModel
          ]
        }
      ).pipe(
        catchError(e => {
          console.error(e);
          this.feedbackService.hide();
          return of(false)
        })
      )
    );
  }

  /**
   * Saves a copy of the grid api for latter usability.
   * @param params
   */
  onGridReady(params: IGridReady) {
    this.gridApi = params.api;
    this.gridApiSig.set(params.api);
    this.colApi = params.columnApi;
    this.gridReady.emit(params);
    this.fitColumns();
    this.setPaginationPageSize();
    this.gridApiInitialized$.next(true);
    // this.gridApi.ensureColIndexVisible(this.apiInstance().custom.displayedLeftColumns[0])
  }

  setPaginationPageSize() {
    if (!this.gridApi) return;
    if (this.gridOptions.pagination !== true) return;
    if (!this.gridElementRefs?.get(0)?.nativeElement) return;
    const hasChanged = this.resetPaginationPageSize();
    if (hasChanged) {
      this.gridApi.onSortChanged();
    }
  }

  resetPaginationPageSize(): boolean {
    let hasChanged = false;
    this.cancel$.next(true);
    this.cancel$.next(false);
    // Force new cache block size by resetting internal cache
    document.querySelector('.ag-paging-panel')
    // Get the panels top position
    const gridHeight = this.gridElementRefs.get(0).nativeElement.querySelector('.ag-paging-panel')?.getBoundingClientRect().top -
      this.gridElementRefs.get(0).nativeElement.querySelector('.ag-header-viewport')?.getBoundingClientRect().bottom
      || this.gridElementRefs.get(0).nativeElement.querySelector('.ag-body-viewport')?.offsetHeight
      || null

    if (!gridHeight) {
      console.warn('No grid height could be calculated. This is probably due to some elements not being rendered yet.');
      return false;
    }

    const rowHeight: number = this.gridOptions.rowHeight;
    const numberOfRowsInViewPort = (Math.floor((gridHeight - this.gridOptions.headerHeight) / rowHeight) + 1);
    // If the fixed page Size is set, fetch double the amount of rows for smoother scrolling UX.
    const cacheBlockSize = Math.max(1, this.fixedPageSize ? Math.min(numberOfRowsInViewPort * 2, this.fixedPageSize) : numberOfRowsInViewPort);
    const pageSize = this.fixedPageSize || cacheBlockSize;
    hasChanged = cacheBlockSize !== this.gridOptions.cacheBlockSize ||
      pageSize !== this.gridOptions.paginationPageSize;
    (this.gridOptions.api as any).gridOptionsWrapper.setProperty('cacheBlockSize', cacheBlockSize);
    (this.gridOptions.api as any).gridOptionsWrapper.setProperty('paginationPageSize', pageSize);
    return hasChanged;
  }


  /**
   * Stores the selected cells value in the clipboard.
   * @param evt
   */
  @Input() onCopyValue(evt: Event) {
    // If the user has selected some string do the browsers native behavior
    if (window.getSelection().toString() != '') return;

    const focusedCell = this.gridApi.getFocusedCell();
    if (!focusedCell) return;

    const rowNode = this.gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);
    const cellValue = this.gridApi.getValue(focusedCell.column, rowNode);
    if (cellValue == null) {
      this.clipboard.copy(null);
      this.clipboardService.clipboardValue = JSON.stringify('');
    } else if (typeof cellValue.value === 'object') {
      this.clipboard.copy(JSON.stringify(cellValue.value));
      this.clipboardService.clipboardValue = JSON.stringify(cellValue.value);
    } else {
      this.clipboard.copy(cellValue.value);
      this.clipboardService.clipboardValue = cellValue.value;
    }
    console.log('Copied to clipboard!', this.clipboardService.clipboardValue);
    this.feedbackService.setNotification(
      'Der Wert wurde in die Zwischenablage kopiert.'
    );
  }

  onPaste(e: ClipboardEvent) {
    console.log('on paste', e)
    // Stop data actually being pasted into the element
    e.stopPropagation();
    e.preventDefault();

    // Get pasted data via clipboard API
    const clipboardData = e.clipboardData;
    const pastedData = clipboardData.getData('Text');
    console.log('Pasted data', pastedData)
    this.onPasteValue(pastedData);
  }

  /**
   * Pastes the stored clipboard value onto the focused cell.
   */
  @Input() onPasteValue(pastedValue: string) {
    if (!pastedValue && pastedValue !== '') throw Error('Could not paste value. Pasted value is undefined.');

    const focusedCell = this.gridApi.getFocusedCell();
    const rowNode = this.gridApi.getDisplayedRowAtIndex(focusedCell.rowIndex);

    if (!focusedCell || !rowNode) {
      console.warn('Could not paste value, because no focused cell could be found.');
      return;
    }
    // // Check if it is an object that got pasted
    // let pasteObject: IPastedObject;
    // try {
    //   let parsedClipboardValue = JSON.parse(pastedValue);
    //   pasteObject = {
    //     clipboardValue: parsedClipboardValue,
    //     column: focusedCell.column.getColId(),
    //   };
    // } catch (e) {
    //   pasteObject = {
    //     clipboardValue: pastedValue,
    //     column: focusedCell.column.getColId(),
    //   };
    // }

    this.gridApi.startEditingCell({
      rowIndex: rowNode.rowIndex,
      colKey: focusedCell.column.getColId(),
      charPress: `#pasted${JSON.stringify(pastedValue)}`,
    });
  }

  onCellValueChanged(evt: CellValueChangedEvent) {
    this.cellValueChanged.emit(evt);
  }

  onRowSelectedEvent(evt: RowSelectedEvent) {
    if (evt == null) return;

    if (this.gridApi) {
      this.selectedRows = this.gridApi.getSelectedRows();
    }
    if (this.automaticallySetRowSelection.has(evt.node.id)) {
      this.automaticallySetRowSelection.delete(evt.node.id);
    } else {
      if (evt.node.isSelected()) {
        this.onRowSelected(evt)
      } else if (evt.node.isSelected() === false) {
        this.onRowDeselected(evt)
      }
    }
  }

  protected onRowSelected(evt: RowSelectedEvent) {
    this.rowSelected.emit(evt);
  }

  protected onRowDeselected(evt: RowSelectedEvent) {
    this.rowDeselected.emit(evt);

  }

  /**
   * Duplicates a line of a table. Calls the api method and fires events afterward.
   */
  duplicateLine(): Observable<any> {
    const selectedRows = this.gridApi.getSelectedRows();
    // get array of objects for mass action
    const objects = selectedRows.map((r) => r.apiRow.obj);
    // find the highest RowIdx to pass to the api function call. The duplications will be inserted at the last row idx then.
    const highestRowIdx = selectedRows.reduce((previousValue, currentValue) => {
      if (previousValue < currentValue.apiRow.rowidx) {
        return currentValue.apiRow.rowidx;
      } else {
        return previousValue;
      }
    }, -1);
    // api call
    return this._tableControlService.tableControlDuplicateLine({
      InstanceId: this.apiInstance().instanceid,
      RowObjects: objects,
      RowIdx: highestRowIdx
    }).pipe(
      tap((res) => {
        this.rowCount = this.rowCount + selectedRows.length;
        // listen to the row create event once to redraw rows after creating / deleting a row
        this.DimensionChanged().subscribe();
        // re-fetch rows
        this.refreshInfiniteCache();

        this.rowCreated.emit();
      }),
      catchError((err) => {
        this.gridApi.hideOverlay();
        return of(err);
      })
    );
  }

  addRowsAndRefetch(rowsAdded: number): void {
    this.rowCount = this.rowCount + rowsAdded;
    // listen to the row create event once to redraw rows after creating / deleting a row
    this.DimensionChanged().subscribe();
    // re-fetch rows
    this.refreshInfiniteCache();

    this.rowCreated.emit();
  }

  /**
   * Creates a new empty row in the table, by calling the api. After receiving
   * a new api-RowInterface the row will be mapped to an ag-grid row and then inserted at the
   * start of the grid row array.
   */
  newLine(editAfterCreate = true): Observable<any> {
    return this._tableControlService.tableControlNewLine({
      InstanceId: this.apiInstance().instanceid
    })
      .pipe(
        tap((row: IApiRow) => {
          this.rowCount++;
          // listen to the row create event once to redraw rows after creating / deleting a row
          // this.DimensionChanged();
          this.DimensionChanged()
            .pipe(
              filter(() => {
                return this.editAfterCreateIndex != null;
              })
            )
            .subscribe(() => {
              if (editAfterCreate) {
                this.gridApi.startEditingCell({
                  rowIndex: row.rowidx,
                  colKey: Object.keys(row.cols)[this.editAfterCreateIndex],
                })
              }
            });
          this.refreshInfiniteCache();
          this.rowCreated.emit(row)
        }),
        catchError((err) => {
          this.gridApi.hideOverlay();
          return of(err);
        })
      );
  }

  /**
   * Deletes x entries of the table. Calls the api method and fires events for notifications to other components.
   */
  deleteLine(): Observable<any> {
    const selectedRows = this.gridApi.getSelectedRows();
    const rowObjects = selectedRows
      .sort((a, b) => a.apiRow.rowidx - b.apiRow.rowidx)
      .map((r) => r.apiRow.obj);
    return this._tableControlService.tableControlDeleteLine({
      InstanceId: this.apiInstance().instanceid,
      RowObjects: rowObjects
    }).pipe(
      tap((res) => {
        const errorRooms: boolean[] = [];

        // successful
        if (res == true) {
          // decrement row count
          this.rowCount = this.rowCount - selectedRows.length;
          // listen to the row create event once to redraw rows after creating / deleting a row
          this.DimensionChanged().subscribe();
          this.rowDeleted.emit();
          // deselect the rows because they got deleted
          this.gridApi.deselectAll();
          this.selectedRows = [];
          this.refreshInfiniteCache();
        } else if (res == false) {
          errorRooms.push(res);
        }
        // show an error if the response is false
        if (errorRooms.length > 0) {
          this.feedbackService.setError(
            `Die Löschung konnte nicht durchgeführt werden. Ein Objekt ist noch in Benutzung.`
          );
          this.gridApi.hideOverlay();
        }
      }),
      catchError((err) => {
        this.gridApi.hideOverlay();
        return of(err);
      })
    );
  }

  deleteSingleLine(apiRow: IApiRow) {
    this._tableControlService.tableControlDeleteLine({
      InstanceId: this.apiInstance().instanceid,
      RowObjects: [apiRow.obj]
    }).subscribe(
      {
        next: (res) => {
          // successful
          if (res == true) {
            // decrement row count
            this.rowCount = this.rowCount - 1;
            // listen to the row create event once to redraw rows after creating / deleting a row
            this.DimensionChanged().subscribe();
            this.rowDeleted.emit();
            // deselect the rows because they got deleted
            this.gridApi.deselectAll();
            this.selectedRows = [];
            this.refreshInfiniteCache();
          }
        },
        error: () => {
          this.gridApi.hideOverlay();
        }
      }
    );
  }

  /**
   * Calls a function of this component by the enum of the function
   * @param controlAction Enum of the action that was triggered
   */
  callActionFunction(controlAction: EControlActions): void {
    this.gridApi.showLoadingOverlay();
    let actionRequest: Observable<any>;
    switch (controlAction) {
      case EControlActions.create:
        actionRequest = this.newLine();
        break;
      case EControlActions.copy:
        actionRequest = this.duplicateLine();
        break;
      case EControlActions.delete:
        actionRequest = this.deleteLine();
        break;
      case EControlActions.selectAll:
        this.selectedAll = true;
        this.refreshInfiniteCache();
        break;
      case EControlActions.csvExport:
        actionRequest = this.downloadCsv();
        break;
      case EControlActions.csvImport:
        actionRequest = of(null).pipe(tap(() => this.fileUploadRef.nativeElement.click()))
        break;
      default:
        this.customAction.emit(controlAction);
        actionRequest = this.actions().find(a => a.controlAction === controlAction).action();
        break;
    }
    if (controlAction !== EControlActions.selectAll) {
      actionRequest.pipe(
        finalize(() => this.gridApi.hideOverlay())
      ).subscribe(() => {
        this.controlStore.controlDataChanged$.emit({changeType: controlAction, GUID: this.GUID});
      });
    }
  }


//
//   class QueuedSlowService {
//   private currentLock = 0;
//   private queue = new BehaviorSubject<number>(0);
//
//   constructor(private slowService: SlowService) {}
//
//   serviceCall(): Observable<number> {
//     return defer(() => {
//       const lock = this.currentLock;
//       this.currentLock++;
//       const result = this.queue.pipe(
//         filter((i) => i === lock),
//         first(),
//         switchMap(() => {
//           return this.slowService.serviceCall().pipe(
//             finalize(() => {
//               this.queue.next(lock + 1);
//             })
//           );
//         })
//       );
//       return result;
//     });
//   }
// }


  /*
  counter = 0;
  queue = new BehaviorSubject(0);

  obs = defer(() => {
    console.log('defer', this.counter);
    const lock = this.counter;
    this.counter++;
    return this.queue.pipe(
      filter((i) => i === lock),
      first(),
      tap(() => console.log('Fetching. Lock', lock)),
      concatMap(() => this.fetch()),
      tap(() => console.log('Fetched. Lock', lock, this.counter)),
      finalize(() =>
        this.queue.next(
          this.counter - lock > 1 ? this.counter - 1 : this.counter
        )
      )
    );
  });


*/

  // Changes the control instance and refreshes the view.
  refresh(): void {
    let filterModel;
    let columnFilter: IColumnFilter[];
    let sortModel;
    let apiSortModel: IApiSortModel;

    // show the loading overlay
    if (this.gridApi) {
      this.gridApi.showLoadingOverlay();
      // Save the selected rows before refreshing the data rows.
      this.savedSelectedRowsIds = this.gridApi.getSelectedRows().map((r) => r.apiRow.id);

      filterModel = this.gridApi.getFilterModel();
      columnFilter = this.getApiFilterModel(filterModel);

      // build column sort
      sortModel = this.colApi.getColumnState().map((c) => {
        return {colId: c.colId, sort: c.sort, sortIndex: c.sortIndex};
      });
      apiSortModel = this.getApiSortModel(sortModel);
    }
    // Cancel ongoing getRows requests.
    this.cancel$.next(true);
    this.cancel$.next(false);
    this.resetRowCache();
    this.selectedRows = [];
    this.selectedAll = false;

    patchState(this.state, state => ({
      filter: {
        ...state.filter,
        columnfilter: columnFilter
      },
      sorting: apiSortModel
    }))

    console.log('filterModel', filterModel);
    console.log('sortModel', apiSortModel);
  }

  /**
   * Automatically sizes the column to the minimum,
   * but expands them if there is enough place to the whole wrapper width.
   */
  fitColumns() {
    if (this.gridApi && this.colApi.getColumnState() != null) {
      const columnIds: string[] = [];
      this.colApi.getColumns().forEach((column) => {
        columnIds.push(column.getColId());
      });
      this.colApi.autoSizeColumns(columnIds, false);
      console.log('visible columns resized');
    }
  }

  getRowNodeId: GetRowIdFunc<ICustomGridRow> = (params: { data: ICustomGridRow }) => {
    return params.data?.apiRow?.id;
  };

  @Input() onCellClicked(evt: CellMouseDownEvent) {
    console.log(evt);
    this.gridApi?.setFocusedCell(evt.rowIndex, evt.column);
    this.cellClicked.emit(evt);
  }

  @Input() onCellContextMenu($event: CellContextMenuEvent) {
  }

  // A filter for the instance changed. (Not column filter)
  onFilterChange(evt: boolean, filter: ITableControlFilter) {
    filter.active = evt;
    const mappedFilters = this.toFilterDto(this.filters());

    const newFilter = {
      ...this.apiInstance().filter,
      ...mappedFilters,
    };
    // check if changed
    if (
      !sameArrayValues(
        Object.keys(this.apiInstance().filter),
        Object.keys(newFilter)
      ) ||
      !sameArrayValues(
        Object.values(this.apiInstance().filter),
        Object.values(newFilter)
      )
    ) {
      this.patchFilter({
        ...mappedFilters,
      })
    }
  }

  toFilterDto(filter: ITableControlFilter[]): Record<string, boolean> {
    return filter.reduce((acc, f) => {
      acc[f.apiAttributeKey] = f.active;
      return acc
    }, {} as Record<string, boolean>)
  }

  // Delayed column filter trigger.
  onColumnFilterChange($event: FilterModifiedEvent) {
    this.columFilterOrSortChange$.emit($event);
  }

  // Delayed column filter trigger.
  onSortChange($event: SortChangedEvent) {
    this.columFilterOrSortChange$.emit($event);
  }

  // Trigger the debounce column change event
  onVirtualColumnChange(evt: VirtualColumnsChangedEvent) {
    this.debouncedVirtualColumnChange$.emit(evt);
  }

  // Maps the internal ag grid filter model to server filter model.
  private getApiFilterModel(filterModel: { [p: string]: any }): IColumnFilter[] {
    if (!filterModel) return null;
    const columnfilter: IColumnFilter[] = [];
    Object.keys(filterModel).forEach((fm) => {
      const colDef = this.colApi
        .getColumn(String(fm))
        .getColDef() as ICustomColDef;
      const apiCol = colDef.apiCol;
      const obj = {
        attribute: apiCol.attribute,
        attributeindex: apiCol.attributeindex,
        expression: filterModel[String(fm)],
      };
      columnfilter.push(obj);
    });
    return columnfilter;
  }

  resetRowCache() {
    console.log('resetting row cache');

    this.viewPortTable.resetCache();
  }

  // Gets the current viewport from the server, adds the row block to the client model and returns the full

  // Maps the ag grid sorting model to the server sort model.
  private getApiSortModel(
    sortModel: {
      sortIndex: number;
      colId: string | undefined; sort: string | null | undefined
    }[]
  ): IApiSortModel {
    if (sortModel.length == null) {
      return {};
    }
    const sortby: string[] = [];
    const direction: (0 | 1)[] = [];
    sortModel.filter(sm => sm.sort != null).sort((a, b) => a.sortIndex - b.sortIndex).forEach((sm) => {
      sortby.push((
        (this.colApi.getColumn(sm.colId).getColDef() as ICustomColDef)
          .attribute +
        ';' +
        (this.colApi.getColumn(sm.colId).getColDef() as ICustomColDef)
          .attributeindex
      ));
      direction.push(sm.sort == 'asc' ? 0 : 1);
    });
    return {
      sortby,
      direction,
    };
  }

  refreshRepositories() {
    const colDefs = this.gridApi.getColumnDefs() as ICustomColDef[];
    // api requests for all columns that have a repository
    const apiReqs = colDefs
      // only for columns with repo
      //   .filter((column: ICustomColDef) => {
      //   return column.repository != null;
      // })
      // map column to api request
      .map((column: ICustomColDef) => {
        if (column.repository != null) {
          return this._tableService.tablePostGetRepositoryForProperty({
            _parameters: [
              this.apiInstance().instanceid,
              column.attribute,
              column.attributeindex
            ]
          });
        } else {
          return of(column);
        }
      });
    // execute all api requests
    forkJoin(apiReqs).subscribe((res: any[]) => {
      res.forEach((repo, index) => {
        if (Array.isArray(repo)) {
          colDefs[index].repository = repo;
        }
      });
      this.gridApi.setColumnDefs(colDefs);
      const editedCell = this.gridApi.getEditingCells()[0];
      // If there was a cell in edit and the editor was the reference selection editor => stop editing and start again, to update the repo.
      if (editedCell) {
        // this.gridApi.stopEditing();
        this.gridApi.startEditingCell({
          rowIndex: editedCell.rowIndex,
          colKey: editedCell.column.getColId(),
        });
      }
      console.log(colDefs);
      console.log(res);
    });
  }

  // row block with current and previous cell data in each row object.
  private fetchViewport(): Observable<ICustomGridRow[]> {
    const currentViewport = this.currentViewPort$.getValue();
    const endRow =
      currentViewport.endRow < this.rowCount ? currentViewport.endRow : this.rowCount;
    const startRow =
      currentViewport.startRow >= endRow ? Math.max(0, endRow - this.gridOptions.cacheBlockSize) : currentViewport.startRow;
    return this._tableService.tablePostFetchRows({
        _parameters: [
          this.apiInstance().instanceid,
          startRow,
          endRow,
          currentViewport.startCol,
          currentViewport.endCol
        ]
      }
    ).pipe(
      catchError(err => {
        this.feedbackService.hide()
        return of(err)
      }),
      map((_: object[]) => {
        const apiRows = _ as IApiRow[];
        const rowsThisBlock = this.apiAdapter.createGridRows(apiRows);
        this.viewPortTable.addRowBlock(
          rowsThisBlock,
          currentViewport,
          this.staticColumns
        );
        this.currentViewPortChanged.emit(this.currentViewPort$.getValue());
        return this.viewPortTable.getRowBlock(currentViewport);
      })
    );
  }

  onRowClicked(evt: RowClickedEvent) {
    // select all should be false now, because the selection
    this.selectedAll = false;
  }

  /**
   * When a row is created or deleted, the table should redraw the rows once after finishing all get rows requests.
   * @constructor
   * @private
   */
  private DimensionChanged(): Observable<number> {
    // Every getRow request is invalid when the table dimension changed.
    this.openGetRowReqs$.next(null);
    return merge(this.rowCreated, this.rowDeleted).pipe(
      mergeMap(() => this.openGetRowReqs$.pipe(debounceTime(500))),
      // only redraw rows when the open http requests are zero
      filter((n: number) => {
        return n === 0;
      }),
      tap((v) => {
        this.cdr.detectChanges();
        this.gridApi.redrawRows();
      }),
      // only do it once
      take(1)
    );
  }

  /**
   * Initializes the infinite row model data source.
   * @param gridApi
   * @private
   */
  private initializeDataSource(gridApi: GridApi, dataSource: IDatasource) {
    // add data source to grid definition
    gridApi.setDatasource(dataSource);
  }

  refreshInfiniteCache() {
    if (this.gridApi == null) return;
    this.cancel$.next(true);
    this.cancel$.next(false);
    this.gridApi.showLoadingOverlay();
    this.gridApi.refreshInfiniteCache();
  }

  private updateRowSpan(colDef: ColDef) {
    if (this.gridApi == null || this.gridApi.getColumnDefs() == null) return;
    colDef = {
      ...colDef, rowSpan: params => {
        if (params.data == null || params.data[params.colDef.field] == null) return 1;
        const grouplength = params.data[params.colDef.field].grouplength;
        console.log(grouplength)
        return grouplength == null ? 1 : grouplength;
      }
    }

    const coldefs = this.gridApi.getColumnDefs()
    if (coldefs == null) return;
    coldefs.forEach((cd: ColDef, i: number) => {
      if (cd.field === colDef.field) {
        coldefs[i] = colDef;
      }
    })

    this.columnDefs = {...coldefs};
  }

  getIsDisabled(action: ITableAction): boolean {
    return (action.disabledIfNoSelection && this.selectedRows.length == 0) ||
      (action.isDisabled != undefined && action.isDisabled()) ||
      (action.disabledIfMultiSelection && this.selectedRows.length > 1);
  }

  getAllRows(): ICustomGridRow[] {
    const rowData: ICustomGridRow[] = [];
    this.gridApi.forEachNode(node => rowData.push(node.data));
    return rowData;
  }

  onGridScroll(evt: BodyScrollEvent) {
    // Clear the previous timeout to avoid multiple calls within the throttle duration
    clearTimeout(this.scrollThrottleTimeout);

    // Set a new timeout to delay the function execution
    this.scrollThrottleTimeout = setTimeout(() => {
      // Handle the horizontal scroll event here
      if (evt.direction === 'horizontal') {
        this.fitColumns();
      }
    }, 1000); // Adjust the throttle duration as needed (e.g., 1000ms = 1 second)
  }

  getRowData() {
    const currentViewport = this.currentViewPort$.getValue();
    const endRow =
      currentViewport.endRow < this.rowCount ? currentViewport.endRow : this.rowCount;
    const startRow =
      currentViewport.startRow >= endRow ? Math.max(0, endRow - this.gridOptions.cacheBlockSize) : currentViewport.startRow;
    return this._tableService.tablePostFetchRows(
      {
        _parameters: [
          this.apiInstance().instanceid,
          startRow,
          endRow,
          currentViewport.startCol,
          currentViewport.endCol
        ]
      });
  }

  private setInitialSelection(gridRow: RowNode) {
    this.automaticallySetRowSelection.add(gridRow.id);
    gridRow.setSelected(true);
  }

  private showPdfOverlay(evt: IPdfBlob, externalData?: boolean) {
    this.gridOptions.paginationAutoPageSize = false;
    this.gridOptions.paginationPageSize = 1;
    const selectedRowIndex = this.selectedRows[0]?.apiRow?.rowidx || 0;

    // Extra Step to ensure there is a change of page, since this triggers some necessary detections
    if (!evt.externalData) {
      this.gridApi.paginationGoToPage(selectedRowIndex + 1);
      this.gridApi.paginationGoToPage(selectedRowIndex);
    }

    const pdfOverlay = this.pdfOverlayRef.nativeElement;
    const elementRef = this.gridElementRefs.get(0);
    const gridWrapperRef = this.gridWrapperRef.nativeElement;
    const selectedRowElements = elementRef.nativeElement
      .querySelectorAll(`#grid-wrapper :not(.ag-hidden) > .ag-row.ag-row-selected`);
    let rowBottom: number = gridWrapperRef.getBoundingClientRect().top;
    selectedRowElements.forEach((el: HTMLElement) => {
      rowBottom = el.getBoundingClientRect().bottom;
    });

    const offset = rowBottom - gridWrapperRef.getBoundingClientRect().top;
    const paginator = elementRef.nativeElement.querySelector('.ag-paging-panel');
    paginator.style.position = 'absolute';
    paginator.style.top = offset + 'px';
    paginator.style.width = '100%';

    pdfOverlay.style.top = offset + paginator.getBoundingClientRect().height + 'px';
    pdfOverlay.style.height = gridWrapperRef.getBoundingClientRect().height - offset - paginator.getBoundingClientRect().height - 10 + 'px';
    pdfOverlay.style.width = '100%';

    if (evt.externalData === true) return;

    const topRowIndex = this.gridApi.paginationGetCurrentPage();

    // Set the focused cell to the top row of the new page
    this.gridApi.setFocusedCell(topRowIndex, "0");

    // Make the top row selected
    this.gridApi.forEachNode(node => {
      if (node.rowIndex === topRowIndex) {
        node.setSelected(true, true);
      } else {
        node.setSelected(false);
      }
    });
    this.gridApi.hideOverlay();
  }

  private hidePdfOverlay() {
    if (!this.pdfOverlayRef || !this.gridApi) return;
    const pdfOverlay = this.pdfOverlayRef.nativeElement;

    pdfOverlay.style.height = '0';

    // Define a named function for the event listener
    const handlePaginationChange = (event: any) => {
      if (!this.gridApi) {
        this.gridApi.removeEventListener('paginationChanged', handlePaginationChange);
      }
      // Retrieve the updated page size when pagination changes
      const pageSize = event.api.paginationGetPageSize();
      const selectedRowIndex = this.selectedRows[0]?.apiRow?.rowidx;

      if (selectedRowIndex) {
        const newPage = Math.floor(selectedRowIndex / pageSize);
        this.gridApi.paginationGoToPage(newPage);
      }

      // Remove the event listener after it has been executed once
      this.gridApi.removeEventListener('paginationChanged', handlePaginationChange);
    }

    // Add an event listener for the paginationChanged event
    this.gridApi.addEventListener('paginationChanged', handlePaginationChange);

    const paginator = this.elementRef.nativeElement.querySelector('.ag-paging-panel');
    paginator.style.position = 'static';
    this.resetPaginationPageSize();
    this.gridApi.hideOverlay();
  }

  @HostListener('window:keydown.delete', ['$event'])
  onDelete(evt: any) {
    const focusedCell = this.gridApi.getFocusedCell();
    if (!focusedCell || this.gridApi.getEditingCells().length > 0) return;
    if (this.gridApi.getEditingCells().length) return;

    this.gridApi.startEditingCell({
      rowIndex: focusedCell.rowIndex,
      colKey: focusedCell.column.getColId(),
      charPress: 'delete'
    })

  }

  private downloadCsv(): Observable<any> {
    return this._tableControlService.tableControlCSVExport(
      {InstanceId: this.apiInstance().instanceid},
      'response',
    ).pipe(
      tap((res: HttpResponse<Blob>) => {
        const fileContent: Blob = res?.body;
        let fileName: string = res.headers.get('content-disposition')?.split('filename=')[1]?.split(';')[0];
        if (fileName) {
          fileName = fileName.substring(1, fileName.length - 1);
        }
        if (fileContent == null) {
          this.feedbackService.setError('Es konnte keine CSV-Datei heruntergeladen werden.');
          return;
        }
        const url: string = URL.createObjectURL(fileContent);
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = fileName || 'export.csv';
        a.click();
        URL.revokeObjectURL(url);
        this.feedbackService.setNotification('Die CSV-Datei wurde heruntergeladen.');
      })
    );
  }

  uploadCsv(evt: Event): Observable<any> {
    const file: File = (evt.target as any).files[0];
    let blob: Blob;

    if (file) {
      const reader: FileReader = new FileReader();
      reader.onload = (e: ProgressEvent<FileReader>) => {
        const csvData: string | ArrayBuffer = e.target?.result;
        if (typeof csvData === 'string') {
          blob = new Blob([csvData], {type: 'text/csv'});
        }
        this._tableService.tablePostCsvImport(this.apiInstance().instanceid, blob).subscribe((res) => {
          if (res) {
            this.changeAndFetchInstance();
          }
        });
      };
      reader.readAsText(file);
    }
    return of(null);
  }

  onTableResize(): void {
    this.setPaginationPageSize();
  }
}
