import {Component, input, output} from '@angular/core';
import {NgIf} from '@angular/common';
import {MatButton} from '@angular/material/button';
import {MatTooltip} from '@angular/material/tooltip';
import {MatIcon} from '@angular/material/icon';
import {ValueOrFunctionPipe} from 'frontier/browserkit/src/lib/pipes/value-or-function.pipe';
import {EControlActions, IAction} from 'frontier';

@Component({
  selector: 'lib-dynamic-form-action',
  standalone: true,
  imports: [
    NgIf,
    MatButton,
    MatTooltip,
    MatIcon,
    ValueOrFunctionPipe
  ],
  templateUrl: './dynamic-form-action.component.html',
  styleUrl: './dynamic-form-action.component.css'
})
export class DynamicFormActionComponent {
  action = input.required<IAction>();
  callActionFunction = output<EControlActions>();
}
