import {
  ChangeDetectorRef,
  Component,
  computed,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  Output,
  Renderer2,
  ViewChild,
  viewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import {BaseFormElementComponent} from './base-form-element.class';
import {TextElementComponent} from './text-element/text-element.component';
import {EFormControlType, IFormElement, IFormElementStyle,} from './form-data.interface';
import {AbstractControl, FormControl} from '@angular/forms';
import {SelectElementComponent} from './select-element/select-element.component';
import {CheckboxElementComponent} from './checkbox-element/checkbox-element.component';
import {DateElementComponent} from './date-element/date-element.component';
import {SearchElementComponent} from './search-element/search-element.component';
import {RadioElementComponent} from './radio-element/radio-element.component';
import {FileUploadElementComponent} from './file-upload-element/file-upload-element.component';
import {TreeSelectComponent} from './tree-select/tree-select.component';
import {MatIcon} from '@angular/material/icon';
import {MatTooltip} from '@angular/material/tooltip';
import {NgIf, NgStyle} from '@angular/common';
import {EsvgFiles} from 'frontier';
import {
  DynamicFormStore
} from 'frontier/browserkit/src/lib/components/control/form-control/dynamic-form/dynamic-form.store';
import {Subscription} from 'rxjs';

const MTypeToComponentMap: Record<number, any> = {
  [EFormControlType.text]: TextElementComponent,
  [EFormControlType.select]: SelectElementComponent,
  [EFormControlType.checkbox]: CheckboxElementComponent,
  [EFormControlType.date]: DateElementComponent,
  [EFormControlType.search]: SearchElementComponent,
  [EFormControlType.radio]: RadioElementComponent,
  [EFormControlType.file]: FileUploadElementComponent,
  [EFormControlType.treeSelect]: TreeSelectComponent,
}

@Component({
  selector: 'kpi4me-form-element',
  templateUrl: './form-element.component.html',
  styleUrls: ['./form-element.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [
    NgStyle,
    MatTooltip,
    NgIf,
    MatIcon,
  ],
})
export class FormElementComponent {
  protected readonly EsvgFiles = EsvgFiles;

  private _dynamicFormStore = inject(DynamicFormStore);
  private _cdr = inject(ChangeDetectorRef);
  private _elRef = inject(ElementRef);
  private _renderer = inject(Renderer2);

  private _subs = new Subscription();

  private _errorIconTooltip = viewChild<MatTooltip>('errorIconTooltip');

  @ViewChild('hostElement', {static: true, read: ViewContainerRef}) hostElement!: ViewContainerRef;

  @Input() formControlElement: AbstractControl;

  private _formData: IFormElement;
  @Input() set formData(formData: IFormElement) {
    if (formData) {
      this._formData = formData;
      this.setStylings(formData.styling);

      const viewContainerRef = this.hostElement;
      viewContainerRef.clear();
      this._cdr.detach();

      const component = MTypeToComponentMap[formData.formControlType];
      const componentRef = viewContainerRef.createComponent<BaseFormElementComponent>(component);
      componentRef.instance.formControlElement = this.formControlElement as FormControl;

      // adjust the options of an enum select to match the structure of select options
      if (formData.formControlType === EFormControlType.select && typeof (formData.value) === "number") {
        // adjust the options of an enum select to match the structure of mat select options
        componentRef.instance.data = formData;
        (componentRef.instance as SelectElementComponent).compareWith = (e1: any, e2: any) => {
          return e1 && e2 && e1 === e2;
        };
      } else {
        componentRef.instance.data = formData;
      }

      componentRef.instance.formControlElement.setValue(formData.value, {emitEvent: false});
      this._initialValue = formData.value;

      // subscribe to the model change event of dialog form elements
      if (this.isDialogFormElement(formData)) {
        this._subs.add(componentRef.instance.modelChange.subscribe(() => {
          this.requestSave.emit(componentRef.instance.formControlElement);
        }))
      }

      this._subs.add(
        componentRef.instance.focusOutEvent.subscribe((evt) => {
          // do not emit the focus out event for dialog form elements
          if (this.isDialogFormElement(formData)) {
            return;
          }

          // if the value is the same as the initial value, do not emit the focus out event
          if (this._initialValue === componentRef.instance.formControlElement.value) {
            return;
          }

          this.focusOutEvent.emit(evt);
        })
      )
      this._cdr.reattach();
    }
  }

  get formData(): IFormElement {
    return this._formData;
  }

  @Output() focusOutEvent = new EventEmitter<FormControl>();
  @Output() requestSave = new EventEmitter<FormControl>();

  /**
   * Errors selected from the store
   */
  storeError = this._dynamicFormStore.error;
  elementError = computed(() => {
    const storeError = this.storeError();

    if (storeError?.element === this.formData?.key) {
      return storeError?.message ?? null;
    }
    return null;
  })

  private _initialValue: any;

  constructor() {
    effect(() => {
      // Display the error tooltip when the error changes
      const elementError = this.elementError();
      const errorIconTooltip = this._errorIconTooltip();
      if (elementError && errorIconTooltip) {
        errorIconTooltip.show();
      }
    });
  }

  private setStylings(stylings: IFormElementStyle) {
    if (!stylings) {
      return;
    }
    if (stylings.rowStart) {
      this._renderer.setStyle(this._elRef.nativeElement, 'grid-row-start', stylings.rowStart);
    }
    if (stylings.rowEnd) {
      this._renderer.setStyle(this._elRef.nativeElement, 'grid-row-end', stylings.rowEnd);
    }
    if (stylings.columnStart) {
      this._renderer.setStyle(this._elRef.nativeElement, 'grid-column-start', stylings.columnStart);
    }
    if (stylings.columnEnd) {
      this._renderer.setStyle(this._elRef.nativeElement, 'grid-column-end', stylings.columnEnd);
    }
    if (stylings.maxWidth) {
      this._renderer.setStyle(this._elRef.nativeElement, 'max-width', stylings.maxWidth);
    }
    if (stylings.cssClass) {
      this._renderer.setAttribute(this._elRef.nativeElement, 'class', stylings.cssClass);
    }
    // if(stylings && stylings.height) {
    //   this.renderer.setStyle(this.elRef.nativeElement, 'height', stylings.height);
    // }

  }

  getFlexDirection() {

  }

  getFlexStyle() {
    if (this.formData == null || this.formData.styling == null) {
      return {'flex-direction': 'row'};
    }
    switch (this.formData.styling.iconPosition) {
      case 'bottom':
        return {'flex-direction': 'column-reverse'};
      case 'left':
        return {'flex-direction': 'row'};
      case 'right':
        return {'flex-direction': 'row-reverse'};
      case 'top':
        return {'flex-direction': 'column'};
    }
  }

  private isDialogFormElement(formData: IFormElement) {
    return formData.formControlType === EFormControlType.select || formData.formControlType === EFormControlType.date;
  }
}
