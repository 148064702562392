import {
  Component,
  effect,
  ElementRef,
  EventEmitter,
  inject,
  input,
  Input,
  InputSignal,
  model,
  ModelSignal,
  Output,
  signal,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import {
  ApiAdapter,
  ECalculateImportValue,
  EControlActions,
  FormControlService,
  IApiControl,
  SignalControl,
} from 'frontier/nucleus';
import {DynamicFormComponent} from '../dynamic-form.component';
import {
  EFormControlType,
  IForm,
  IFormGroup,
  ISelectFormElement,
  ISelectFormOption,
  TAnyFormElement,
} from '../form-element/form-data.interface';
import {BehaviorSubject, concatMap, Observable, of, Subject} from "rxjs";
import {catchError, filter, map, take, tap} from "rxjs/operators";
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {NgIf} from '@angular/common';
import {FeedbackService, IAction} from "frontier/browserkit";
import {HttpErrorResponse, HttpResponse} from "@angular/common/http";
import {MatError} from '@angular/material/form-field';
import {
  DynamicFormStore
} from 'frontier/browserkit/src/lib/components/control/form-control/dynamic-form/dynamic-form.store';

export class DefaultFormApiAdapter extends ApiAdapter {
  from(apiData: IForm): IForm {
    apiData.formGroups.forEach((fg: IFormGroup) => {
      fg.elements.forEach((fe: TAnyFormElement) => {
        if (fe.formControlType === EFormControlType.select) {
          let selectFe: ISelectFormElement = fe as ISelectFormElement;
          const optionType = typeof selectFe.options[0];
          if (optionType === 'string') {
            selectFe.createNewEnabled = false;
            selectFe.editElementEnabled = false;
            selectFe.deleteElementEnabled = false;
            selectFe.options = selectFe.options.map((o: ISelectFormOption, i: number) => {
              return {
                value: i,
                name: String(o),
              } as ISelectFormOption;
            })
          }
          else if (optionType === "object") {
            selectFe.options = selectFe.options.map((o: ISelectFormOption) => {
              return {
                value: o,
                name: String(o.name),
              } as ISelectFormOption;
            })
          } else {
            selectFe.options = selectFe.options.map((o: ISelectFormOption, i: number) => {
              return {
                value: i,
                name: String(o),
              } as ISelectFormOption;
            })
          }
        }
      })
    })
    return apiData;
  }

  to(data: any): any {
    return data;
  }
}

export interface IFormChange {
  elements: TAnyFormElement[];
  formGroup: IFormGroup;
  changedElement?: string;
}

@Component({
  selector: 'kpi4me-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  standalone: true,
  imports: [NgIf, DynamicFormComponent, MatError],
})
export abstract class DynamicFormControlComponent extends SignalControl<IForm, IForm> {
  abstract override GUID: string;
  protected _formControlService: FormControlService = inject(FormControlService);

  protected dynamicFormStore = inject(DynamicFormStore);
  protected readonly feedbackService: FeedbackService = inject(FeedbackService);

  protected _selectedElement: any;

  @ViewChild(DynamicFormComponent) formRef: DynamicFormComponent;
  @ViewChild('fileUpload') fileUploadRef: ElementRef;
  @Input() apiAdapter: ApiAdapter = new DefaultFormApiAdapter();
  @Input() displayHeaders: boolean = true;
  @Input() standardForm: boolean = true;

  actions: ModelSignal<IAction[]> = model<IAction[]>([]);
  showImports: InputSignal<boolean> = input<boolean>(false);
  error = signal<string | null>(null);

  @Input() onFormChange(evt: IFormChange) {
    this.isFormChanging$.next(true);
    console.log('form change', evt);
    this.formChange$.next(evt);
  }

  @Input() set selectedElement(element: any) {
    this.isFormChanging$.pipe(
      filter((v: boolean): boolean => v === false),
      take(1),
      tap(() => this._selectedElement = element)
    ).subscribe()
  };

  get selectedElement() {
    return this._selectedElement;
  }

  @Output() formChange: EventEmitter<IForm> = new EventEmitter<IForm>();

  protected formChange$: Subject<IFormChange> = new Subject<IFormChange>();
  protected isFormChanging$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  override toModel(data: IForm): IForm {
    return this.apiAdapter.from(data);
  }

  constructor() {
    super();

    effect(() => {
      const apiInstance: IApiControl = this.apiInstance();
      const showImports: boolean = this.showImports();
      if (apiInstance) {
        this.patchFilter({
          calculateimportvalue: showImports ? ECalculateImportValue.first : ECalculateImportValue.off,
        })
      }
    }, {allowSignalWrites: true})

    this.formChange$.pipe(
      takeUntilDestroyed()
    ).pipe(
      concatMap((evt: IFormChange) => {
          return this._formControlService.formControlChangeValues({
            InstanceId: this.instanceId(),
            formElements: evt.elements,
            dbobject: this.selectedElement ? this.selectedElement : null
          }).pipe(
            catchError((e) => {
              this.handleError(e, evt.changedElement);
              return of(null);
            }),
            map((res: IForm) => ({res, evt}))
          )
        }
      ),
      tap(({res, evt}: { res: IForm, evt: IFormChange }) => {
        if (res) {
          // reset error
          this.error.set(null);
          this.dynamicFormStore.error$.next(null);

          res = this.apiAdapter.from(res);
          console.log(res)

          // find the respective form group and update the form
          const respectiveFormGroup: IFormGroup = res.formGroups.find((fg: IFormGroup): boolean => fg.header === evt.formGroup.header);
          const formGroup = respectiveFormGroup.elements.reduce((acc: any, e: TAnyFormElement) => {
            acc[e.key] = e.value;
            return acc;
          }, {});

          this.updateForm(formGroup);

          // emit the form change event
          this.controlStore.controlDataChanged$.emit({GUID: this.GUID, changeType: EControlActions.changeLine});
          this.formChange.emit(res);

          this.isFormChanging$.next(false);
        }
      }),
      catchError((e) => {
        console.log(e);
        return of(null)
      })
    ).subscribe()
  }

  /**
   * Calls the respective action function based on the control action.
   * @param controlAction
   */
  callActionFunction(controlAction: EControlActions): void {
    let actionRequest: Observable<any>;
    switch (controlAction) {
      case EControlActions.jsonExport:
        actionRequest = this.downloadJson();
        break;
      case EControlActions.jsonImport:
        actionRequest = of(null).pipe(tap(() => this.fileUploadRef.nativeElement.click()))
        break;
    }
    actionRequest?.subscribe(() => {
      this.controlStore.controlDataChanged$.emit({changeType: controlAction, GUID: this.GUID});
    });
  }

  private downloadJson(): Observable<any> {
    return this._formControlService.serviceConfigFormControlExportConfig(
      {InstanceId: this.instanceId()},
      'response',
    ).pipe(
      tap((res: HttpResponse<Blob>) => {
        const fileContent: Blob = res?.body;
        let fileName: string = res.headers.get('content-disposition')?.split('filename=')[1]?.split(';')[0];
        if (fileName) {
          fileName = fileName.substring(1, fileName.length - 1);
        }
        if (fileContent == null) {
          this.feedbackService.setError('Es konnte keine JSON-Datei heruntergeladen werden.');
          return;
        }
        const url: string = URL.createObjectURL(fileContent);
        const a: HTMLAnchorElement = document.createElement('a');
        a.href = url;
        a.download = fileName || 'export.json';
        a.click();
        URL.revokeObjectURL(url);
        this.feedbackService.setNotification('Die JSON-Datei wurde heruntergeladen.');
      })
    );
  }

  uploadJson(evt: Event): Observable<any> {
    let json: string;
    const fileRead = (evt.target as any).files[0];
    const reader: FileReader = new FileReader();
    reader.onloadend = (e: ProgressEvent<FileReader>) => {
      // read result of FileReader
      json = e.target.result as string;
      this._formControlService.serviceConfigFormControlImportConfig({
        json: JSON.parse(json),
        InstanceId: this.instanceId(),
      }).subscribe((res) => {
        if (res) {
          this.changeAndFetchInstance();
        }
      });
    };
    reader.readAsText(fileRead);
    return of(null);
  }

  /**
   * Should display an error either on the formgroup or the element.
   * For the element error, the stores error is set.
   * @param e
   * @param changedElement
   * @private
   */
  protected handleError(e: HttpErrorResponse, changedElement?: string) {
    this.error.set(null);
    this.dynamicFormStore.error$.next(null);
    if (changedElement) {
      this.dynamicFormStore.error$.next({message: e.error.error.userMsg || 'Ungültiger Wert', element: changedElement});
    } else {
      this.error.set(e.error.error.userMsg || 'Das Formular konnte nicht gespeichert werden.');
    }
  }

  /**
   * Updates the form with the given form group.
   * @param value the form group value to update the form with
   */
  protected updateForm(value: any) {
    this.formRef.form.patchValue(value, {emitEvent: false});
  }
}
